import dayjs from 'dayjs';
import { useNuxtApp } from '#imports';

export const useDateFormatter = () => useNuxtApp().$dt;

export const normalizeDateTimezone = (date: dayjs.ConfigType, providedTimezone?: number) =>
  dayjs(date)
    .subtract(providedTimezone ?? 0, 'second')
    .add(dayjs().utcOffset() * 60, 'second');

export const validateDate = (date: string) => {
  const validateMonth = (month?: string) => month && +month >= 1 && +month <= 12;
  const validateDay = (day?: string) => day && +day >= 1 && +day <= 31;

  return (
    !!date &&
    dayjs(date, 'YYYY-MM-DD').isValid() &&
    validateMonth(date.split('-')[1]) &&
    validateDay(date.split('-')[2])
  );
};

export const validateDepartDate = (date: string) => {
  if (validateDate(date) && dayjs(date).diff(dayjs(), 'day') >= 0) return date;
  else return dayjs().add(1, 'day').format('YYYY-MM-DD');
};

export const validateReturnDate = (returnDate: string, departDate: string) => {
  if (validateDate(returnDate) && dayjs(returnDate).diff(dayjs(departDate), 'day') >= 0) return returnDate;
  else return dayjs(departDate).add(2, 'day').format('YYYY-MM-DD');
};

export const validateCheckInDate = (date: string) => {
  if (validateDate(date) && dayjs(date).diff(dayjs(), 'day') >= 0 && dayjs(date).diff(dayjs(), 'day') < 365)
    return date;
  else return dayjs().add(1, 'day').format('YYYY-MM-DD');
};

export const validateCheckOutDate = (co: string, ci: string) => {
  if (validateDate(co) && dayjs(co).diff(dayjs(ci), 'day') >= 1 && dayjs(co).diff(dayjs(ci), 'day') <= 30) return co;
  else return dayjs(ci).add(2, 'day').format('YYYY-MM-DD');
};
